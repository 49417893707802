import React from 'react'
import { TextField as LibInputField } from '@redwoodjs/web'

import { Field, Label } from 'src/ui'

export const InputField = ({ className, label, name, ...props }: Field) => (
  <Label
    className={className}
    label={label}
    {...props}
    name={name}
    component={LibInputField}
    componentProps={{ className: 'form-input', name }}
  />
)

export default InputField
