import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from 'src/ui'

type Option = {
  id: string
  name: string
  description?: string
  selected?: boolean
  value?: string
}

type SelectingFieldProps = PropsWithChildren<{}> & {
  name: string
  onChange: (e: { currentTarget: { value: Option[] } }) => void
  options: Option[]
  className?: string
  label?: string
  multiple?: boolean
  placeholder?: string
  selected?: Option[]
}

type OptionProps = PropsWithChildren<{}> &
  Option & {
    onClick: (option: Option) => void
  }

const Option = ({
  description,
  id,
  name,
  onClick,
  selected,
  value = '',
  ...props
}: OptionProps) => {
  const _className = useMemo(
    () => classNames({ 'text-left pl-2': true, 'bg-green-200': selected }),
    [selected]
  )

  return (
    <Button
      className={_className}
      onClick={() => onClick({ name, value, id, description, ...props })}
      text={((selected && '> ') || '') + name}
    />
  )
}

type PickerProps = PropsWithChildren<{}> &
  Pick<SelectingFieldProps, 'options' | 'multiple'> & {
    onTogglePicker: () => void
    onChange: (selection: Option[]) => void
    selectionState
  }

const Picker = ({
  multiple,
  onTogglePicker,
  onChange,
  options,
  selectionState,
}: PickerProps) => {
  const [selection, setSelection] = selectionState
  const [filterInput, setFilterInput] = useState<string>('')

  /**
   * Compose options:
   * - on initial rendering
   * - on selection change
   */
  const _options = useMemo(() => {
    const selectionMap = new Map(
      Object.entries(
        Object.fromEntries(
          selection.map((o) => [o.name, { ...o, selected: true }])
        )
      )
    )
    const _options = selectionMap.size
      ? new Map(
          Object.entries(
            Object.fromEntries(
              options
                .filter((o) => !selectionMap.has(o.name))
                .map((o) => [o.name, { ...o, selected: false }])
            )
          )
        ).values()
      : options
    return [...selectionMap.values(), ..._options].filter((o) =>
      o.name.toLowerCase().includes(filterInput.toLowerCase())
    )
  }, [filterInput, options, selection])

  const onPick = useCallback(
    (option: Option) => {
      const s = Object.fromEntries(selection.map((v) => [v.name, v]))
      if (s[option.name]) {
        // If option is already selected: deselect. It's a toggle event.
        delete s[option.name]
      } else {
        // If select single, we delete all other entries to add only the new one.
        if (!multiple) {
          Object.keys(s).map((index) => {
            delete s[index]
          })
        }
        // Add the new option
        s[option.name] = option
      }
      const _selection: Option[] = Object.values(s)
      setSelection(_selection)
      onChange(_selection)
    },
    [multiple, onChange, selection, setSelection]
  )

  return (
    <div className={'flex flex-col absolute mt-16 z-50 bg-gray-200 p-2'}>
      <div className={'flex items-center'}>
        <input
          className={'form-input m-2 px-4 py-2 '}
          onChange={({ currentTarget: { value } }) => setFilterInput(value)}
          placeholder={'Search...'}
        />
        <span className={'mr-4'} onClick={onTogglePicker}>
          x
        </span>
      </div>
      <div className={'flex flex-col overflow-y-auto h-56'}>
        {_options.map((o) => (
          <Option key={o.name} {...o} onClick={onPick} />
        ))}
      </div>
    </div>
  )
}

export function SelectingField({
  className = '',
  label = '',
  multiple = true,
  placeholder = 'Select...',
  options,
  selected = [],
  onChange,
}: SelectingFieldProps) {
  const { t } = useTranslation()
  const selectionState = useState(selected)
  const [openPicker, setOpenPicker] = useState<boolean>(false)

  const _label = useMemo(() => {
    const [selection] = selectionState
    if (selection.length) {
      return selection.map((o) => o.name).join(', ')
    }
    return placeholder
  }, [placeholder, selectionState])

  const onTogglePicker = useCallback(() => setOpenPicker(!openPicker), [
    openPicker,
  ])

  const _onChange = useCallback(
    (selection) => onChange({ currentTarget: { value: selection } }),
    [onChange]
  )

  return (
    <label
      className={classNames({
        [className]: className,
        'flex flex-col m-2 w-full': true,
        'md:w-1/4': !className.includes('md:w-'),
      })}
    >
      {label && (
        <span className={'ml-4 text-grey-700 text-xs'}>{t(label)}</span>
      )}
      <Button
        color={'black'}
        pointer={_label}
        className={'form-select truncate text-left pl-2 pr-6'}
        onClick={onTogglePicker}
      />
      {openPicker && (
        <Picker
          multiple={multiple}
          onTogglePicker={onTogglePicker}
          onChange={_onChange}
          options={options}
          selectionState={selectionState}
        />
      )}
    </label>
  )
}

export default SelectingField
