import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react'
import randomItem from 'random-item'
import classNames from 'classnames'
import { Sizes } from 'src/ui/Enums'

import { Event } from 'src/ui'

import avatars from './svg'
// import './avatar.scss'

export type AvatarVariants = 'circle' | 'rounded' | 'square'

export type AvatarProps<T> = PropsWithChildren<T> & {
  icon?: string | void
  onClick?: Event<T> //(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  preSelected?: boolean
  selected?: boolean
  size?: Sizes
  src?: string | void
  variant?: AvatarVariants
  containerClassname: string
}

export const Avatar: FunctionComponent<AvatarProps<{}>> = ({
  selected = false,
  preSelected = false,
  variant = 'circle',
  size = Sizes.standard,
  src,
  onClick,
  containerClassname,
  ...props
}) => {
  const _containerClassName = useMemo(
    () =>
      classNames({
        [containerClassname]: containerClassname,
        'flex justify-center self-center p-1': true,
        'border shadow cursor-pointer': onClick,
        'bg-white': selected,
      }),
    [containerClassname, onClick, selected]
  )
  const _className = useMemo(
    () =>
      classNames({
        rounded: 'circle' === variant,
      }),
    [variant]
  )

  /** Images components content. **/

  const _src = useMemo(() => (src && !avatars[src] ? src : undefined), [src])

  return (
    <div className={_containerClassName} onClick={onClick}>
      {_src && <img src={_src} className={_className} />}
    </div>
  )
}

export default Avatar
