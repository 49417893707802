import React, { FunctionComponent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@redwoodjs/web'
import { navigate, routes } from '@redwoodjs/router'
import gql from 'graphql-tag'
import Label from 'src/components/Labels/Label'
import LabelCell from 'src/components/Labels/LabelCell'
import useNotification from 'src/ui/useNotification/useNotification'

import { CellEmpty, Tip, useGrid } from 'src/ui'

export const QUERY = gql`
  query QueryLabels {
    labels {
      id
      name
      ownershipsCount
    }
  }
`

const MUTATION_DELETE_LABEL = gql`
  mutation DELETE_LABEL($id: String) {
    deleteLabel(id: $id) {
      status
      message
    }
  }
`

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'cache-and-network' }
}

export const Loading = () => <div>Loading...</div>

export const Empty = () => <CellEmpty pointer={'pages:labels.empty'} />

export const Failure = ({ error }) => <div>Error: {error.message}</div>

type SuccessProps = {
  labels: []
}

export const Success: FunctionComponent<SuccessProps> = ({ labels }) => {
  const { t } = useTranslation()
  const { notifier, setNotification } = useNotification()

  /** Mutations. **/
  const [deleteLabel, { loading, data }] = useMutation(MUTATION_DELETE_LABEL, {
    refetchQueries: [{ query: QUERY }],
    onCompleted: ({ deleteLabel: { status, message } }) => {
      setNotification({ message, variant: status })
    },
  })

  /** Callbacks. **/
  const onDelete = useCallback(
    ({ id }) => {
      if (confirm(t('pages:labels.delete'))) {
        deleteLabel({ variables: { id } })
      }
    },
    [deleteLabel, t]
  )

  /** Components. **/
  const { gridComponent } = useGrid({
    collection: labels,
    component: Label,
    componentProps: { className: 'w-full md:w-1/6' },
    onClick({ id }) {
      navigate(routes.label({ id }))
    },
    onDelete,
    gridProps: { title: 'pages:labels.title', className: ' justify-start' },
  })

  return (
    <div className={'flex flex-col flex-wrap'}>
      {notifier}
      {gridComponent}
      <div
        className={'flex flex-wrap w-full mt-4 items-stretch justify-center'}
      >
        <Tip pointer={'tips:labels.curate'} inGrid={true} />
        <Tip pointer={'tips:labels.hierarchy'} inGrid={true} />
        <Tip pointer={'tips:labels.creativity'} inGrid={true} />
      </div>
    </div>
  )
}
