import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Colors, Sizes } from 'src/ui/Enums'

import { Button, Dialog, DialogActions, Form as FormUi, Text } from 'src/ui'

import SignUp from './Signup'
import SignIn from './SignIn'
import Social from './Social'

type GateProps<T> = PropsWithChildren<T> & {
  direction: 'in' | 'up'
  open: boolean
  onClose: () => void
}

export default function Gate({ direction, open, onClose }: GateProps<{}>) {
  const { t } = useTranslation()
  return (
    <Dialog open={open} fullScreen={true}>
      <DialogActions>
        <Button onClick={onClose} text={t('site:Close')} />
      </DialogActions>
      <FormUi variant={'wide'} title={t('forms:user.signup.title')}>
        <div className={'flex flex-col md:flex-row items-baseline py-2'}>
          <div className={'flex flex-col w-full items-center md:w-5/12'}>
            <Social />
          </div>
          <div className={'md:w-2/12 md:self-center'}>
            <div>
              <Text className={'w-full text-center'}>or</Text>
            </div>
          </div>
          <div className={'flex flex-col items-center md:w-5/12'}>
            {'in' === direction && <SignIn />}
            {'up' === direction && <SignUp />}
          </div>
        </div>
        {'up' === direction && (
          <Text size={Sizes.small} color={Colors.primary}>
            {t('forms:user.signup.disclaimer')}
          </Text>
        )}
      </FormUi>
    </Dialog>
  )
}
