import React, { useEffect, useState } from 'react'
import { useMutation } from '@redwoodjs/web'
import { isISBN } from 'class-validator'
import gql from 'graphql-tag'
import { Colors, Sizes, TypographyComponent } from 'src/ui/Enums'

import { InputField, Text } from 'src/ui'

const SEARCH_BOOK = gql`
  mutation SearchBook($isbn: String) {
    searchBook(isbn: $isbn) {
      data {
        title
        id
        covers
      }
      message
      status
    }
  }
`

export default function CollectIsbn({ record }) {
  const [isbn, setIsbn] = useState<void | number | string>('')
  const [error, setError] = useState<string>('')

  const [searchBook, { loading }] = useMutation(SEARCH_BOOK, {
    onCompleted: ({ searchBook: { data, status, message } }) => {
      if ('SUCCESS' === status) {
        record({ selectedReference: data, status, message })
      } else {
        record({ status, message, selectedReference: null })
      }
    },
  })

  useEffect(() => record({ loading }), [loading, record])

  useEffect(() => {
    if (10 > isbn.length) return
    const errorMessage = !isISBN(isbn)
      ? 'Invalid ISBN - please check the reference and correct the number.'
      : ''

    setError(errorMessage)

    if (isISBN(isbn)) {
      searchBook({ variables: { isbn } })
    }
  }, [isbn, record, searchBook])

  return (
    <div className={'space-y-4'}>
      <InputField
        name={'isbn'}
        label={'ISBN'}
        className={'w-full'}
        value={isbn}
        onChange={({ currentTarget }) => setIsbn(currentTarget.value)}
      />
      <Text component={TypographyComponent.text} size={Sizes.tiny}>
        Ex: 9780553213119 978-0486411095 0141439556 2070612880 2851310070
      </Text>
      {error && <Text color={Colors.alert}>{error}</Text>}
    </div>
  )
}

export { CollectIsbn }
