import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@redwoodjs/auth'
import { Colors, Sizes } from 'src/ui/Enums'

import { Button, Text } from 'src/ui'

export default function Social() {
  const { t } = useTranslation()
  const { logIn, client } = useAuth()

  const facebookLogin = useCallback(() => {
    const provider = new client.auth.FacebookAuthProvider()
    client.auth().signInWithRedirect(provider)
  }, [client])

  return (
    <>
      <Text
        key={'social__title'}
        size={Sizes.big}
        color={Colors.primary}
        className={'mb-2 self-start'}
      >
        {t('forms:user.signup.intro.social')}
      </Text>
      <div key={'social__buttons'} className={'flex space-x-2 w-full'}>
        <Button onClick={logIn} text={'Google'} color={Colors.secondary} />
        <Button
          onClick={facebookLogin}
          text={'Facebook'}
          color={Colors.secondary}
        />
      </div>
    </>
  )
}
