import React, {
  createElement,
  Dispatch,
  PropsWithChildren,
  Reducer,
  ReducerAction,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from '@redwoodjs/forms'

import { Button } from 'src/ui'

import { Filter } from './'

export type FilterUiProps = PropsWithChildren<object> & {
  filters: Array<Filter>
  record: Dispatch<ReducerAction<Reducer<any, any>>>
  displayNb?: number
  resetForm?: () => void
}

/**
 * Return array o of objects reduced to either the id prop or the name prop.
 * @param o
 */
export const predicateOnArray = (o: []) => {
  return o.map(({ id, name }) => id ?? name)
}

export const FilterUi = ({
  filters,
  displayNb,
  record,
  resetForm,
}: FilterUiProps) => {
  const { t } = useTranslation()

  const [more, setMore] = useState(false)

  const renderFilterPortion = (begin: number, limit: number | null = null) =>
    filters
      .slice(begin, limit || filters.length)
      .map(({ component, name, componentProps }) =>
        createElement(component, {
          ...componentProps,
          className: 'w-full md:w-4/12',
          label: t(componentProps.label),
          key: componentProps.label,
          name,
          onChange(e: {
            currentTarget: { value: string }
            target: { value: any }
          }) {
            const { value } = e?.currentTarget ?? e?.target
            const input =
              typeof value === 'string'
                ? value?.toLowerCase()
                : Array.isArray(value)
                ? predicateOnArray(value)
                : value
            record({
              [name]: input,
            })
          },
        })
      )

  return (
    <Form
      className={
        'w-full sticky top-0 mx-auto bg-white rounded md:content-center md:w-4/6 md:self-center'
      }
    >
      <div className={'flex flex-wrap flex-col md:flex-row justify-around'}>
        {filters && renderFilterPortion(0, displayNb)}
        {more && displayNb && filters && renderFilterPortion(displayNb)}
      </div>
      <div className={'m-auto'}>
        {displayNb && filters.length > displayNb && (
          <Button
            onClick={() => setMore(!more)}
            text={t((!more && 'more') || 'less')}
          />
        )}
      </div>
      {/** Not working. yet. **/}
      {false && resetForm && <Button onClick={resetForm} text={'Reset'} />}
    </Form>
  )
}

export default FilterUi
