import React, { useCallback } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@redwoodjs/web'
import { Share } from '@material-ui/icons'
import useRecordState from 'src/System/Hooks/useRecordState'
import { useTranslation } from 'react-i18next'
import useNotification from 'src/ui/useNotification/useNotification'

import { Button, Form as FormWrapper } from 'src/ui'

import ReviewLoan from './AddLoan/ReviewLoan'
import LoanTip from './AddLoan/LoanTip'
import SelectOwnerships from './AddLoan/SelectOwnerships'
import SelectContact from './AddLoan/SelectContact'

export const Action = () => ({
  title: 'Loan',
  icon: <Share />,
  tooltipTitle: 'Loan',
})

export const Pointer = 'rights.canAddLoans'

const MUTATION_BEGIN_LOAN = gql`
  mutation beginLoan($userId: String!, $ownerships: [String!]!) {
    beginLoan(userId: $userId, ownerships: $ownerships) {
      status
      message
    }
  }
`

export const Form = () => {
  const { t } = useTranslation()

  const { notifier, setNotification } = useNotification()

  const [{ user, ownerships }, record] = useRecordState({
    ownerships: [],
    user: null,
  })

  /** Mutations & queries. **/
  const [loanCallback] = useMutation(MUTATION_BEGIN_LOAN, {
    onCompleted: ({ status, message }) =>
      setNotification({ message, variant: status }),
  })

  /** Callbacks. */
  const selectOwnerships = useCallback((ownerships) => record({ ownerships }), [
    record,
  ])

  const selectContact = useCallback((user) => record({ user: user.pop() }), [
    record,
  ])

  const submitCallback = useCallback(
    (e) => {
      e.preventDefault()
      const ownershipsId = ownerships.map(({ id }) => id)
      const userId = user.id
      loanCallback({ variables: { ownerships: ownershipsId, userId } })
    },
    [loanCallback, user, ownerships]
  )

  return (
    <FormWrapper
      variant={'narrow'}
      title={'Lend a book'}
      uiOnly={false}
      className={'space-y-4'}
    >
      {notifier}
      <div className="flex flex-col md:justify-around">
        <SelectOwnerships onChange={selectOwnerships} />
        <SelectContact onChange={selectContact} />
      </div>
      <Button
        className={'w-full'}
        onClick={submitCallback}
        text={t('forms:Loan.submit')}
      />
      <ReviewLoan ownerships={ownerships} user={user} />
      <LoanTip />
    </FormWrapper>
  )
}
