import React, { PropsWithChildren, useMemo } from 'react'
import { User as UserData } from '@prisma/client'

import { ItemComponentProps, Item } from 'src/ui'

type UserProps<T> = PropsWithChildren<T> &
  ItemComponentProps<T> &
  UserData /*{
    id: string
    lastname: string
    firstname: string
    email: string
    onDelete?: () => void
  }*/

export default function User(props: UserProps) {
  const data = useMemo(
    () => ({
      id: props.id,
      lastname: props.lastname,
      firstname: props.firstname,
      email: props.email,
    }),
    [props]
  )
  return (
    <Item
      title={`${props.firstname ?? ''} ${props.lastname ?? ''}`}
      subtitle={props.email}
      data={data}
      variant={'preview'}
      {...props}
    />
  )
}
