import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Label } from '@material-ui/icons'
import { Form as RWForm, Submit, useMutation } from '@redwoodjs/web'
import gql from 'graphql-tag'
import classNames from 'classnames'
import { QUERY as LabelsGrid } from 'src/components/Labels/LabelsCell'
import useNotification from 'src/ui/useNotification/useNotification'

import { Form as FormUi, InputField, Tip } from 'src/ui'

const Action = () => ({
  title: 'features:label.Add',
  icon: <Label />,
  tooltipTitle: 'features:label.Add',
})
export const Pointer = 'rights.canAddLabels'

type FormProps = PropsWithChildren<object> & {}

const ADD_LABEL = gql`
  mutation AddLabel($name: String) {
    createLabel(name: $name) {
      message
      status
    }
  }
`

const Form: FunctionComponent<FormProps> = () => {
  const { t } = useTranslation()
  const [newLabel, setNewLabel] = useState<string>()
  const { notifier, setNotification } = useNotification()

  const [createLabel, { loading }] = useMutation(ADD_LABEL, {
    refetchQueries: [{ query: LabelsGrid }],
    onCompleted: ({ createLabel }) => {
      setNotification({
        message: createLabel.message,
        variant: createLabel.status,
      })
    },
  })

  const onClick = useCallback(() => {
    if (newLabel && 3 <= newLabel.length) {
      createLabel({ variables: { name: newLabel } })
    }
  }, [createLabel, newLabel])

  const onSubmit = useCallback(
    ({ Label }) => {
      if (Label && 3 <= Label.length) {
        createLabel({ variables: { name: Label } })
      }
    },
    [createLabel]
  )

  return (
    <FormUi
      title={'features:label.Add'}
      variant={'narrow'}
      className={'flex flex-col space-y-4 py-2'}
    >
      <RWForm onSubmit={onSubmit}>
        {notifier}
        <InputField
          name={'Label'}
          label={t('features:label.new')}
          validation={{ required: true, minLength: 3 }}
          message={'The label must be 3 letters long at least'}
        />
        <Submit
          disabled={loading}
          className={classNames({
            'w-full color-primary my-2 bg-white rounded border py-2': true,
            'bg-blue': loading,
          })}
        >
          {t('forms:Add')}
        </Submit>
        <Tip pointer={'tips:labels.threeLetters'} />
      </RWForm>
    </FormUi>
  )
}

export { Action, Form }
