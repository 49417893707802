import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Book as BookType } from '@prisma/client'
import { ItemComponentProps } from 'src/ui/Item'
import { Sizes } from 'src/ui/Enums'

import { Item, Text } from 'src/ui'

export type BookProps<T> = PropsWithChildren<T> &
  ItemComponentProps<T> &
  BookType

const Book: FunctionComponent<BookProps<object>> = (props) => {
  const _compose = ({
    id,
    className,
    variant,
    title,
    covers,
    authors,
    ...props
  }) => ({
    variant,
    ...props,
    className,
    title: title,
    details: [
      Boolean(authors?.length) && (
        <span key={`${title}--authors`}>
          <Text size={Sizes.tiny}>
            {authors.map(({ name }) => name).join(', ')}
          </Text>
        </span>
      ),
    ],
    avatarProps: { src: covers, variant: 'rounded', size: Sizes.big },
    data: { id, title, covers, authors },
  })

  return <Item {..._compose(props)} />
}

export default Book
