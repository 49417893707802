import React, { useCallback, useMemo } from 'react'
import { useQuery } from '@redwoodjs/web'
import gql from 'graphql-tag'

import { SelectingField } from 'src/ui'

const QUERY = gql`
  query GET_LOANABLE_OWNERSHIPS {
    ownerships: getLoanableOwnerships {
      id
      stage
      book {
        title
      }
    }
  }
`

function compose(ownerships) {
  return ownerships.map(({ id, book, stage }) => ({
    id,
    name: book.title,
    title: book.title,
    book,
    stage,
  }))
}

export default function SelectOwnerships({ onChange }) {
  const { data } = useQuery(QUERY)

  const options = useMemo(() => data?.ownerships && compose(data.ownerships), [
    data,
  ])

  const _onChange = useCallback(
    ({ currentTarget: { value } }) => onChange(value),
    [onChange]
  )

  return (
    <>
      {options && (
        <SelectingField
          className={'md:w-full'}
          label={'Books'}
          name={'ownerships'}
          onChange={_onChange}
          options={options}
          placeholder={'Choose some books...'}
        />
      )}
    </>
  )
}
