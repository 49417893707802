import React, { FunctionComponent, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { useAuth } from '@redwoodjs/auth'
import Notification from 'src/ui/Notification/Notification'
import Dialer from 'src/components/Dialer/Dialer'

import AppBar from './AppBar'

import './applayout.scss'

const AppLayout: FunctionComponent<{
  children: ReactElement[] | ReactElement
}> = ({ children }): ReactElement => {
  const { isAuthenticated } = useAuth()
  const notifications = useSelector(({ notifications }) => notifications)

  return (
    <div
      className={
        'container flex flex-col md:content-center md:mx-auto md:px-4 space-y-4'
      }
    >
      <AppBar />
      {0 < notifications.length && <Notification {...notifications.shift()} />}
      <div className="flex flex-col content-center md:w-11/12 mx-auto">
        {children}
      </div>
      {isAuthenticated && <Dialer />}
    </div>
  )
}

export default AppLayout
