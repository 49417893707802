import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form as FunctionalForm } from '@redwoodjs/forms'
import classNames from 'classnames'
import { Colors, Sizes } from 'src/ui/Enums'

import { Text } from 'src/ui'

type FormProps = PropsWithChildren<{}> & {
  variant: 'narrow' | 'wide'
  title?: string
  className?: string
}

const formClassName = ({ className, variant }) =>
  classNames({
    [className]: true,
    'form p-4 md:self-center md:mb-4': true,
    'md:w-4/6': variant === 'wide',
    'w-full md:w-4/12': variant === 'narrow',
    'flex flex-col': true,
  })

function Functional({
  children,
  className,
  title,
  variant,
  ...props
}: FormProps) {
  const { t } = useTranslation()
  return (
    <FunctionalForm
      {...props}
      className={formClassName({ className, variant })}
    >
      {title && (
        <Text size={Sizes.massive} color={Colors.primary}>
          {t(title)}
        </Text>
      )}
      {children}
    </FunctionalForm>
  )
}

function Ui({ children, className, title, variant }: FormProps) {
  const { t } = useTranslation()

  return (
    <div className={formClassName({ className, variant })}>
      {title && (
        <Text size={Sizes.massive} color={Colors.primary}>
          {t(title)}
        </Text>
      )}
      {children}
    </div>
  )
}

const Form: FunctionComponent<FormProps & { uiOnly?: boolean }> = ({
  uiOnly = true,
  ...props
}) => {
  return useMemo(
    () => (uiOnly ? <Ui {...props} /> : <Functional {...props} />),
    [props, uiOnly]
  )
}

export default Form

export { Form }
