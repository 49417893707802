import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Colors, Rates as RatesEnum, Sizes } from 'src/ui/Enums'

import { Avatar, DataEvent, Event, Text } from 'src/ui'
import './rates.scss'

enum RateIcons {
  sold = 'financial',
  readIntent = 'book3',
  buyIntent = 'book3',
  keeper = 'notebook',
  required = 'book3',
}

type RateButtonProps = {
  selected: boolean
  variant: RatesEnum
  onClick?: DataEvent<{}>
}

type RateProps = {
  mode?: 'edition' | 'addition'
  selected?: RatesEnum
  onChange?: DataEvent<{}>
}

function RateButton({ selected, variant, onClick }: RateButtonProps) {
  const icon = useMemo(() => RateIcons[RatesEnum[variant]], [variant])

  const _onClick = useCallback(
    (e) => {
      if (onClick) {
        return onClick({ id: variant.toString(), variant }, e)
      }
    },
    [variant, onClick]
  )

  return (
    (icon && (
      <Avatar
        src={icon}
        onClick={_onClick}
        variant={'square'}
        selected={selected}
      />
    )) ||
    null
  )
}

function Rate({
  mode = 'addition',
  onChange,
  selected = RatesEnum.buyIntent,
}: RateProps) {
  const { t } = useTranslation()
  const [_selected, setSelected] = useState<number | string | RatesEnum>(
    selected
  )

  const onClick: DataEvent<any> = ({ variant }, e) => {
    setSelected(variant)
    if (onChange) {
      onChange({ id: variant, variant }, e)
    }
  }

  return (
    <div>
      {
        /** You have a rate and don't have a change method: **/ selected &&
          !onChange && (
            <RateButton variant={_selected as RatesEnum} selected={true} />
          )
      }
      {
        /** You have a change method: **/ onChange && (
          <div>
            <Text color={Colors.primary}>
              {t(
                'addition' === mode
                  ? 'forms:AddBook.ownOnRateClick'
                  : 'forms:AddBook.changeRateClick'
              )}
            </Text>
            <div className={'flex flex-wrap'}>
              <div
                className={
                  'w-4/12 flex flex-col justify-around mt-2 md:w-full md:flex-row '
                }
              >
                {
                  /** Rates are displayed here. **/ Object.entries(RatesEnum)
                    .filter(([, k]) => typeof k !== 'string')
                    .map(([k, v]) => (
                      <RateButton
                        key={v}
                        selected={v === _selected}
                        variant={RatesEnum[k]}
                        onClick={onClick}
                      />
                    ))
                }
              </div>
              {
                /** Display the description for selected Rate. **/ _selected && (
                  <Text
                    className={'w-8/12 md:w-full mt-2'}
                    color={Colors.discreet}
                    size={Sizes.tiny}
                  >
                    {t(`features:ratings.rates.${RatesEnum[_selected]}`)}
                  </Text>
                )
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Rate
export { RateButton, Rate }
