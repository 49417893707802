import React, { PropsWithChildren } from 'react'
import { ItemChildren, ItemEvents } from 'ui'

type ClickOrNotProps<T> = PropsWithChildren<T> &
  Pick<ItemChildren<T>, 'className'> &
  Pick<ItemEvents<T>, 'onClick'>

/**
 * This component creates for us a clickable element if necessary. */
export const ClickOrNot = ({
  children,
  className,
  onClick,
}: ClickOrNotProps<{}>) => {
  return typeof onClick === typeof Function ? (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault()
        if (onClick) {
          return onClick(e)
        }
      }}
      className={className}
    >
      {children}
    </a>
  ) : (
    <div className={className}>{children}</div>
  )
}

export default ClickOrNot
