import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { Book } from '@material-ui/icons'
import { Colors } from 'src/ui/Enums'

import { Form as FormUi, Text } from 'src/ui'

import CollectSelectedReference from './AddBook/CollectSelectedReference'
import CollectIsbn from './AddBook/CollectIsbn'

import '../dialer.scss'

const Action = () => ({
  title: 'features:book.Add',
  icon: <Book />,
  tooltipTitle: 'features:book.Add',
})

export const Pointer = 'rights.canAddBooks'

const AddBookDataState = {
  selectedReference: false,
  loading: false,
}

const Form = () => {
  const { t } = useTranslation()

  /**
   * Form Data collection.
   */
  const [{ loading, selectedReference, status, message }, record] = useReducer(
    (state, action) => ({
      ...state,
      ...action,
    }),
    AddBookDataState,
    undefined
  )

  return (
    <FormUi variant={'narrow'} uiOnly={false} title={t('features:book.Add')}>
      <CollectIsbn record={record} />
      {!loading && selectedReference && (
        <CollectSelectedReference
          record={record}
          selectedReference={selectedReference}
        />
      )}
      {!loading && status === 'FAILURE' && message && (
        <Text color={Colors.alert}>{message}</Text>
      )}
      {loading && (
        <Text
          color={Colors.discreet}
          pointer={'Loading, loading, loading...'}
        />
      )}
    </FormUi>
  )
}

export { Action, Form }
