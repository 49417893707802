import {
  ButtonComponent,
  Colors,
  Sizes,
  TypographyComponent,
} from 'src/ui/Enums'

export const MapComponent = {
  [TypographyComponent.paragraph]: 'p',
  [ButtonComponent.link]: 'a',
  [TypographyComponent.text]: 'span',
}

export const MapSize = {
  [Sizes.tiny]: 'text-xs',
  [Sizes.small]: 'text-sm',
  [Sizes.standard]: 'text-base',
  [Sizes.big]: 'text-2xl',
  [Sizes.massive]: 'text-3xl',
}

export const MapColor = {
  [Colors.primary]: 'color-primary',
  [Colors.secondary]: 'color-secondary',
  [Colors.discreet]: 'color-discreet',
  [Colors.alert]: 'color-alert',
  [Colors.success]: 'color-success',
}
