import { Dispatch, useReducer } from 'react'

type Data<T> = T & {
  [prop: string]: any
  modified: boolean
}

type UseRecordStateReturn<T> = Array<Data<T> | Dispatch<any>>

export function useRecordState<T>(init: T): UseRecordStateReturn<T> {
  return useReducer(
    (s: Data<T>, a): Data<T> => ({ ...s, ...a, modified: true }),
    { ...init, modified: false }
  )
}

export default useRecordState
