import React from 'react'
import { useSelector } from 'react-redux'
import { navigate } from '@redwoodjs/router'
import classNames from 'classnames'

import { Button, Notification } from 'src/ui'

/**
 * @param backRoute A Focus layout would only be accessed via one entry point.
 * @param children
 * @constructor
 */
export const FocusLayout = ({ backRoute, children }) => {
  const notifications = useSelector(({ notifications }) => notifications)

  return (
    <div className={'flex flex-col container justify-center'}>
      {backRoute && (
        <Button
          onClick={() => navigate(backRoute)}
          pointer={'site:Close'}
          className={'self-end m-2'}
        />
      )}
      <div
        className={classNames({
          'container flex flex-col space-y-4': true,
          'md:content-center md:mx-auto md:px-4': true,
        })}
      >
        {0 < notifications.length && (
          <Notification {...notifications.shift()} />
        )}
        <div
          className={classNames({
            'flex flex-col content-center': true,
            'md:w-11/12 mx-auto': true,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
export default FocusLayout
