import React, { createElement, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog, DialogActions, Button, Cell } from 'src/ui'

type Action = {
  label: string
  onClick: () => any
}

type EditorProps = PropsWithChildren<object> & {
  id: string
  cell: Cell
  close: () => void
  dialogActions?: Action[] | null
}

export const Editor: (props: EditorProps) => JSX.Element = function ({
  id,
  cell,
  close,
  dialogActions,
}) {
  const { t } = useTranslation()
  return (
    <Dialog open={true} fullScreen={true}>
      <DialogActions>
        <Button
          variant={'outlined'}
          key={`actions__close`}
          onClick={close}
          text={t('Close')}
        />
        {dialogActions &&
          dialogActions.map(({ label, onClick, ...props }: Action) => (
            <Button
              variant={'outlined'}
              key={`actions__${label}`}
              onClick={onClick}
              {...props}
              text={label}
            />
          ))}
      </DialogActions>
      {createElement(cell, { id })}
    </Dialog>
  )
}
export default Editor
