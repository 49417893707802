import React, { useCallback, useState } from 'react'

import { Slide, Snackbar } from 'src/ui'

import { Variants } from './index'
import './notification.scss'

type NotificationProps = {
  message: string
  variant?: Variants
  duration?: number
  cleanUp?: () => void
}

export const Notification: (props: NotificationProps) => JSX.Element = ({
  duration = 6000,
  message,
  variant = 'success',
  cleanUp,
}) => {
  const [open, setOpen] = useState<boolean>(Boolean(message))

  const onClose = useCallback(() => {
    setOpen(false)
    if (cleanUp) {
      cleanUp()
    }
  }, [cleanUp])

  return (
    <Snackbar
      autoHideDuration={duration}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      className={`notification`}
      ContentProps={{
        className: `notification__paper--${variant?.toLowerCase()}`,
      }}
      message={message}
      onClose={onClose}
      open={open}
      TransitionComponent={Slide}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
      // @ts-ignore
      TransitionProps={{ direction: 'left' }}
    />
  )
}

export default Notification
