import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Translation } from 'react-i18next'
import { Colors, Sizes } from 'src/ui/Enums'
import classNames from 'classnames'

import { Text, TranslatedContent } from 'src/ui/'

export type TipProps = TranslatedContent & {
  className?: string
  inGrid?: boolean
  titlePointer?: string
  variant?: 'wide' | 'narrow'
  bottomChildren?: PropsWithChildren<any>
  bottomPlaceholderProps?: Record<string, any>
}

export const Tip: FunctionComponent<TipProps> = ({
  children,
  className,
  inGrid = false,
  pointer,
  titlePointer = 'site:tip',
  variant = 'wide',
  bottomChildren,
  bottomPlaceholderProps,
}) => (
  <Translation>
    {(t) => (
      <div
        className={classNames({
          [`${className}`]: className,
          'h-42 px-4 py-6': true,
          'm-2': variant === 'narrow' || inGrid,
          'md:flex-1': inGrid,
          'bg-white': true,
          'md:self-center': !inGrid,
          'md:w-1/3 m-2': variant === 'narrow',
        })}
      >
        <div className={'flex flex-col justify-between h-full'}>
          <div>
            <Text className="text-center underline" size={Sizes.small}>
              {t(titlePointer)}
            </Text>
            <Text color={Colors.alert} size={Sizes.small}>
              {t(pointer)}
            </Text>
          </div>
          {children && <div>{children}</div>}
          {bottomChildren && (
            <div
              {...bottomPlaceholderProps}
              className={classNames({
                'w-full mt-4': true,
                [bottomPlaceholderProps?.className ??
                '']: bottomPlaceholderProps?.className,
              })}
            >
              {bottomChildren}
            </div>
          )}
        </div>
      </div>
    )}
  </Translation>
)

export default Tip
