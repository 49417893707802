import React from 'react'
import { Form, Submit } from '@redwoodjs/web'
import { useAuth } from '@redwoodjs/auth'
import { Colors, Sizes } from 'src/ui/Enums'
import { useTranslation } from 'react-i18next'

import { InputField, Text } from 'src/ui'

export default function SignIn() {
  const { t } = useTranslation()
  const { client } = useAuth()

  const onSubmit = ({ sesame, email }) => {
    client.auth().signInWithEmailAndPassword(email, sesame)
  }

  return (
    <Form className={'flex flex-col'} onSubmit={onSubmit}>
      <Text
        size={Sizes.big}
        color={Colors.primary}
        className={'mb-2 self-start'}
        pointer={'forms:user.signin.intro.old'}
      />
      <div className={'flex flex-col'}>
        <InputField name={'email'} label={'forms:user.signin.email.label'} />
        <InputField
          name={'sesame'}
          label={'forms:user.signin.password.label'}
        />
      </div>
      <div>
        <Submit
          className={'w-full color-primary my-2 bg-white rounded border py-2'}
        >
          {t('forms:user.signin.enter')}
        </Submit>
      </div>
    </Form>
  )
}
