import React, { ReactElement } from 'react'
import { navigate, routes } from '@redwoodjs/router'
import { useTranslation } from 'react-i18next'
import { AppBar as MuiAppBar } from '@material-ui/core'
import UserMenu from 'src/layouts/AppLayout/UserMenu'
import ConnectionUi from 'src/layouts/AppLayout/ConnectionUi'
import { ButtonComponent, Sizes } from 'src/ui/Enums'

import { Button } from 'src/ui'

const AppBar = (): ReactElement => {
  const { t } = useTranslation()
  return (
    <MuiAppBar className={'appbar'} color={'transparent'} position={'static'}>
      <div className="logo">
        <Button
          onClick={() => navigate(routes.home())}
          component={ButtonComponent.link}
          size={Sizes.big}
          text={t('site:name')}
        />
      </div>
      <div className="flex flex-row w-full justify-around items-baseline md:w-2/3">
        <UserMenu />
        <ConnectionUi />
      </div>
    </MuiAppBar>
  )
}

export default AppBar
