import { createElement, useMemo, useReducer, useState } from 'react'

import { ItemData, OrNull } from 'src/ui'

import FilterUi from './FilterUi'

import { UseFilteringParams, UseFilteringResult } from './'

export const useFiltering: (
  props: UseFilteringParams
) => UseFilteringResult = ({
  collection,
  filters,
  /** Number of items needed in the collection before filtering is made available. */
  filterThreshold = 7,
  uiProps,
}) => {
  const [selectedFilters, record] = useReducer((s, a) => ({ ...s, ...a }), {})

  const [reset, setReset] = useState(false)

  /**
   * Composing the UI for filters. This happens once, for external use only.
   */
  const filterUi = useMemo<OrNull<JSX.Element>>(() => {
    if (filterThreshold) {
      return createElement(FilterUi, {
        filters,
        ...uiProps,
        resetForm: () => setReset(true),
        record,
      })
    }
    return null
  }, [record, filterThreshold, filters, uiProps])

  /**
   * Organizing filter methods within an object where keys = filter_name.
   * This allows the parent component to give an array of filters,
   * instead of writing an object (commodity).
   */
  const _composeOnFilterEvents = useMemo<object>(
    () =>
      filters.reduce((carry, { name, fn }) => {
        return { ...carry, [name]: fn }
      }, {}),
    [filters]
  )

  /**
   * Composing the results based on the filters.
   * Using a memo here proved to be safe & swift, going w/ state
   * meant falling into a permanent loop
   */
  const results = useMemo<ItemData[]>(() => {
    if (reset) {
      setReset(false)
      return collection
    }
    // @ts-ignore
    return Object.entries(selectedFilters).reduce(
      (ComposingResult: ItemData[], [name, input]) => {
        return input
          ? ComposingResult.filter((data) =>
              _composeOnFilterEvents[name](
                data,
                'string' === typeof input ? input?.toLocaleLowerCase() : input
              )
            )
          : ComposingResult
      },
      collection
    )
  }, [selectedFilters, collection, _composeOnFilterEvents, reset])

  return {
    filterUi,
    results,
  }
}

export default useFiltering
