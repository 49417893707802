import { uid } from 'rand-token'
import React, {
  createElement,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Sizes } from 'src/ui/Enums'

import { Data, Item, ItemEvents, ItemProps, Text, TextField } from 'src/ui'
type Input = {
  title: string
  data: Data
}

type SelectProps = PropsWithChildren<object> & {
  variant?: 'single' | 'multiple'
  source: Input[]
  onChange: (selection: Array<object> | object) => void
  filteringStatement: (data: Omit<Data, 'id'>, input: string) => boolean
  title: string
  className?: string
  sourceType: FunctionComponent<ItemProps<{}>>
}

type SelectionProps<T> = Pick<SelectProps, 'variant' | 'sourceType'> &
  Pick<ItemEvents<T>, 'onDelete'> & {
    selection: Array<Input>
  }

const Selection: FunctionComponent<SelectionProps<{}>> = ({
  variant = 'single',
  sourceType = Item,
  selection,
  onDelete,
}) => {
  return (
    <div className="flex flex-wrap">
      {selection &&
        selection.map((item) =>
          createElement(sourceType, {
            ...item,
            key: `Selected ${uid(16)}`,
            onDelete,
            className: 'w-full',
            variant: variant === 'multiple' ? 'chip' : 'bare',
          })
        )}
    </div>
  )
}

/** Selection callbacks - consider making a Hook. * */
const SelectMultipleCallback = (data: Data, state: object) => ({
  ...state,
  [data.id]: data,
})
const DeselectMultipleCallback = (data: Data, state: object) => {
  let s = {}
  Object.entries(state).map(([k, v]) => {
    if (k !== data.id) {
      s = { ...s, [k]: v }
    }
  })
  return s
}
const SelectSingleCallback = (data: Data) => ({ [data.id]: data })
const DeselectSingleCallback = () => ({})

const Select: FunctionComponent<SelectProps> = ({
  variant = 'single',
  className = '',
  onChange,
  source,
  sourceType = Item,
  title,
  filteringStatement,
}) => {
  /** Props. * */
  const { t } = useTranslation()
  const [filter, setFilter] = useState<string>()
  const [selection, setSelection] = useState<object>({})

  const list = useMemo<Array<Input>>(() => {
    return source
      ? !filter
        ? source
        : source.filter((data) => filteringStatement(data, filter))
      : []
  }, [filter, filteringStatement, source])

  useEffect(
    () =>
      onChange(
        variant === 'multiple'
          ? Object.values(selection)
          : Object.values(selection).pop()
      ),
    [onChange, selection, variant]
  )

  /** Callbacks. * */
  const _SelectionCallback = useMemo(
    () =>
      variant === 'multiple' ? SelectMultipleCallback : SelectSingleCallback,
    [variant]
  )

  const _DeselectionCallback = useMemo(
    () =>
      variant === 'multiple'
        ? DeselectMultipleCallback
        : DeselectSingleCallback,
    [variant]
  )

  const SelectionCallback = useCallback(
    (data) => {
      if (!selection[data.id]) {
        const s = _SelectionCallback(data, selection)
        setSelection(s)
      }
    },
    [selection, _SelectionCallback]
  )

  const DeselectionCallback = useCallback(
    (data) => {
      const s = _DeselectionCallback(data, selection)
      setSelection(s)
    },
    [selection, _DeselectionCallback]
  )

  return (
    <div className={`full flex flex-col p-4 md:m-2 md:rounded-lg ${className}`}>
      <Text size={Sizes.small}>{t(title, { total: list.length })}</Text>
      {
        /** We can filter if the list is too long. * */ list.length > 4 && (
          <TextField
            label={t('forms:Select.search')}
            onChange={({ currentTarget: { value } }) => setFilter(value)}
          />
        )
      }
      <div className="select__list flex flex-col overflow-y-auto">
        {list.map((item) =>
          createElement(sourceType, {
            key: `Option ${uid(list.length)}`,
            ...item,
            variant: 'bare',
            onClick: SelectionCallback,
          })
        )}
      </div>
      {selection && (
        <div>
          <Text size={Sizes.tiny}>{t('forms:Select.selection')}</Text>
          <Selection
            sourceType={sourceType}
            variant={variant}
            onDelete={DeselectionCallback}
            selection={Object.values(selection)}
          />
        </div>
      )}
    </div>
  )
}

export default Select
export { Select }
