import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import validator from 'validator'
import gql from 'graphql-tag'
import { Form, useMutation, useQuery } from '@redwoodjs/web'
import { useTranslation } from 'react-i18next'
import User from 'src/components/User/User'
import useRecordState from 'src/System/Hooks/useRecordState'
import useNotification from 'src/ui/useNotification/useNotification'
import { QUERY as ContactsQuery } from 'src/components/Contact/ContactsCell'
import { Sizes } from 'src/ui/Enums'

import { Button, InputField, Text, Tip } from 'src/ui'

const MUTATION_ADD_CONTACT = gql`
  mutation ADD_CONTACT($id: String) {
    addContact(id: $id) {
      status
      message
    }
  }
`

const MUTATION_DECLARE_CONTACT = gql`
  mutation DECLARE_CONTACT(
    $handle: String
    $email: String
    $lastname: String
    $firstname: String
  ) {
    declareContact(
      handle: $handle
      email: $email
      lastname: $lastname
      firstname: $firstname
    ) {
      status
      message
    }
  }
`

const QUERY_SEARCH_USER = gql`
  query SEARCH_USER($email: String) {
    searchUser(email: $email) {
      id
      firstname
      lastname
      email
    }
  }
`

const validateEmail = (email) => validator.isEmail(email)
/*email.match(
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  )*/

type FormDataState = {
  email: string
  firstname: string
  lastname: string
  userHandle: string
}

const FormDataState: FormDataState = {
  email: '',
  firstname: '',
  lastname: '',
  userHandle: '',
}

const _refetchIndex = {
  refetchQueries: [{ query: ContactsQuery }],
}

const AddContact: FunctionComponent = () => {
  const { t } = useTranslation()
  const { notifier, setNotification } = useNotification()

  const [formData, record] = useRecordState<FormDataState>(FormDataState)

  const isValidEmail = useMemo(() => validateEmail(formData.email), [
    formData.email,
  ])

  /** Queries & Mutations. **/
  const [addContact] = useMutation(MUTATION_ADD_CONTACT, {
    ..._refetchIndex,
    onCompleted: ({ addContact: { message, status } }) =>
      setNotification({ message, variant: status }),
  })

  const [declareContact] = useMutation(MUTATION_DECLARE_CONTACT, {
    ..._refetchIndex,
    onCompleted: ({ declareContact: { message, status } }) =>
      setNotification({ message, variant: status }),
  })

  const { data, refetch } = useQuery(QUERY_SEARCH_USER, {
    variables: { email: formData.email },
  })

  useEffect(() => {
    if (validateEmail(formData.email)) {
      refetch()
    }
  }, [formData, refetch])

  /** Conditions. **/
  const [shouldAdd, shouldDeclare] = useMemo(
    () => [
      Boolean(isValidEmail && data && data.searchUser),
      Boolean(isValidEmail && (!data || !data.searchUser)),
    ],
    [data, isValidEmail]
  )

  /** Callbacks. **/
  const SearchCallback = useCallback(
    (email) => {
      if (validateEmail(email)) {
        record({ email })
      }
    },
    [record]
  )

  const AddCallback = useCallback(() => {
    if (shouldDeclare) {
      const handle = formData.userHandle
      return declareContact({
        variables: { handle, ...formData },
      })
    }
    if (shouldAdd) {
      return addContact({ variables: { id: data.searchUser.id } })
    }
  }, [addContact, declareContact, shouldDeclare, shouldAdd, data, formData])

  return (
    <Form>
      <div className={'flex flex-col space-y-4'}>
        {notifier}
        <InputField
          name={'search'}
          label={t('forms:contact.email.label')}
          onChange={({ currentTarget: { value } }) => SearchCallback(value)}
        />
        {
          /** We found a user based on a valid email that we can get in touch with.**/
          shouldAdd && (
            <>
              <Text size={Sizes.small}>{t('forms:contact.add.shouldAdd')}</Text>
              <User {...data.searchUser} />
            </>
          )
        }
        {
          /** We will create a bogus user - they will be able to take possession of it later. **/ shouldDeclare && (
            <>
              <Text size={Sizes.small}>
                {t('forms:contact.add.shouldDeclare')}
              </Text>
              <InputField
                name={'handle'}
                label={t('forms:contact.handle.label')}
                onChange={({ currentTarget: { value } }) =>
                  record({ userHandle: value })
                }
              />
              <InputField
                name={'lastname'}
                label={t('forms:contact.lastname.label')}
                onChange={({ currentTarget: { value } }) =>
                  record({ lastname: value })
                }
              />
              <InputField
                name={'firstname'}
                label={t('forms:contact.firstname.label')}
                onChange={({ currentTarget: { value } }) =>
                  record({ firstname: value })
                }
              />
            </>
          )
        }
        {(shouldAdd || shouldDeclare) && (
          <Button
            className={'pt-4'}
            onClick={AddCallback}
            text={t('forms:Add')}
          />
        )}
        {shouldDeclare && <Tip pointer={'tips:contacts.warnThem'} />}
      </div>
    </Form>
  )
}

export default AddContact
