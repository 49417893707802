import React, { FunctionComponent, useCallback } from 'react'
import { Colors, Sizes } from 'src/ui/Enums'

import { Button, OrNull, Text, Tip } from 'src/ui'
import { ColdGrey, Gold, Green, TaupeBlue, Red } from 'src/theme'

import useRecordState from '../../System/Hooks/useRecordState'

import { Stages } from './index'

export const LifeCycle: Record<
  Stages,
  { title: string; description: string; color: string }
> = {
  not_owned: {
    title: 'Absentee',
    description: "You don't own this book nor plan to.",
    color: ColdGrey,
  },
  prospect: {
    title: 'Whishlisted',
    description: 'You plan to acquire this book.',
    color: Red,
  },
  idle: {
    title: 'Idle',
    description: 'There is nothing to say about this book.',
    color: Gold,
  },
  important: {
    title: 'Important',
    description: 'This book has a strong place in your library.',
    color: Green,
  },
  required: {
    title: 'Shining star',
    description: 'This book is among the very few you will not live without.',
    color: TaupeBlue,
  },
}

type SelectedStageProps = { stage: Stages }

const SelectedStage: FunctionComponent<SelectedStageProps> = ({ stage }) => {
  const { title, color, description } = LifeCycle[stage]
  return (
    <div className={'flex flex-col w-auto m-auto items-center space-y-2'}>
      <Text
        size={Sizes.small}
        color={Colors.primary}
        className={'w-32 text-center'}
      >
        <span
          className={'flex w-4 mr-4 h-4'}
          style={{ backgroundColor: color }}
        />
        {title}
        <span
          className={'flex w-4 ml-4 h-4'}
          style={{ backgroundColor: color }}
        />
      </Text>
      <Text size={Sizes.tiny} color={Colors.secondary}>
        {description}
      </Text>
    </div>
  )
}

type CycleProps = {
  stage?: Stages
  onChange: (stage: Stages) => void
}

export const Cycle: FunctionComponent<CycleProps> = ({ onChange, stage }) => {
  const [state, record] = useRecordState<{ stage: OrNull<Stages> }>({ stage })

  const _onChange = useCallback(
    (stage: Stages) => {
      onChange(stage)
      record({ stage })
    },
    [onChange, record]
  )

  return (
    <div className={'flex flex-col items-center p-4 space-y-4'}>
      <div className={'flex flex-row space-x-2 mspace-y-0 w-64 justify-center'}>
        {Object.keys(LifeCycle)
          .filter((s) => true || s !== state.stage)
          .map((key) => (
            <Button
              className={'flex p-4 w-0 border-0'}
              key={key}
              style={{
                color: 'white',
                backgroundColor: LifeCycle[key].color,
              }}
              value={key}
              onClick={({ currentTarget: { value } }) => _onChange(value)}
            />
          ))}
      </div>
      {state.stage && <SelectedStage stage={state.stage} />}
      {!state.stage && (
        <Tip
          inGrid={false}
          pointer={'Select a stage to add the book to your library'}
        />
      )}
    </div>
  )
}

export default Cycle
