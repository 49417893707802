import React, {
  createElement,
  FunctionComponent,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab'

import { Button, Dialog, DialogActions } from 'src/ui'

import useConfiguration from '../../System/Hooks/useConfiguration'

import * as Actions from './Actions'

import './dialer.scss'

const getKey = (pointer) => pointer.split('.').pop()

const Dialer = () => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const [openId, setOpenId] = useState<string | null>()

  const pointers = useMemo(
    () => [...Object.values(Actions).map(({ Pointer }) => Pointer)],
    []
  )

  const configuration = useConfiguration(pointers)

  const availableActions: null | {
    [prop: string]: {
      Action: Function
      Form: FunctionComponent
      Pointer: string
      opened: boolean
    }
  } = useMemo(() => {
    if (configuration) {
      return Object.fromEntries(
        Object.entries(Actions)
          .filter(([_index, { Pointer }]) => configuration[getKey(Pointer)])
          .map(([index, value]) => [
            index,
            { ...value, opened: index === openId },
          ])
      )
    }
    return null
  }, [configuration, openId])

  /**
   * Callbacks.
   */
  const onOpen = () => setOpen(true)

  const onClose = () => setOpen(false)

  const onCloseDialog = () => setOpenId(null)

  const onClickDial = (Name) => setOpenId(Name)

  return (
    <div>
      <SpeedDial
        className={'Dialer'}
        ariaLabel={'Add'}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        direction={'up'}
        icon={<SpeedDialIcon />}
      >
        {availableActions &&
          Object.entries(availableActions).map(([Name, { Action }]) => (
            <SpeedDialAction
              key={Name}
              {...Action()}
              title={t(Action().title)}
              tooltipTitle={t(Action().subtitle)}
              onClick={() => onClickDial(Name)}
            />
          ))}
      </SpeedDial>
      {availableActions &&
        Object.entries(availableActions).map(([Name, { Form }]) => (
          <Dialog
            key={Name}
            className={'Dialer__Dialog'}
            open={availableActions[Name].opened ?? false}
            fullScreen={true}
            PaperProps={{ elevation: 20 }}
          >
            <DialogActions>
              <Button
                variant={'outlined'}
                onClick={onCloseDialog}
                text={t('site:Close')}
              />
            </DialogActions>
            {createElement(Form, { onClose: onCloseDialog })}
          </Dialog>
        ))}
    </div>
  )
}

export default Dialer
