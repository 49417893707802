import React, { ReactElement, useEffect, useState } from 'react'
import { useAuth } from '@redwoodjs/auth'
import { useTranslation } from 'react-i18next'
import Gate from 'src/components/User/Signup/Gate'
import { Sizes } from 'src/ui/Enums'

import { Button } from 'src/ui'

const ConnectionUi = (): ReactElement => {
  const { t } = useTranslation()
  const { isAuthenticated, logOut } = useAuth()

  const [direction, setDirection] = useState<'in' | 'up' | null>()

  return (
    <>
      {!isAuthenticated && (
        <div className={'flex space-x-2'}>
          <Button onClick={() => setDirection('in')} text={'Sign in'} />
          <Button onClick={() => setDirection('up')} text={'Sign up'} />
          {direction && (
            <Gate
              open={true}
              onClose={() => setDirection(null)}
              direction={direction}
            />
          )}
        </div>
      )}
      {isAuthenticated && (
        <Button onClick={logOut} size={Sizes.small} text={t('Logout')} />
      )}
    </>
  )
}

export default ConnectionUi
