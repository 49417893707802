import React from 'react'

import { Tip } from 'src/ui'

export default function LoanTip() {
  return (
    <Tip
      titlePointer={'Safety first'}
      pointer={
        "A loan is most efficient with a confirmation. Ask your friend to confirm they got the books you say they're borrowing, it's a security for both"
      }
    />
  )
}
