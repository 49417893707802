import favourite from './favourite_004.svg'
import cloud from './cloud_006.svg'
import search from './search_018.svg'
import shelf from './shelf_020.svg'

export default {
  favourite,
  cloud,
  search,
  shelf,
}
