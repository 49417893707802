import React, { FunctionComponent, useState } from 'react'
import { Form, FormError, Submit, useMutation } from '@redwoodjs/web'
import { useAuth } from '@redwoodjs/auth'
import { Translation, useTranslation } from 'react-i18next'
import gql from 'graphql-tag'
import { Colors, Sizes } from 'src/ui/Enums'

import { Dialog, DialogActions, InputField, Text } from 'src/ui'

const MUTATION_CREATE_USER = gql`
  mutation CREATE_USER($data: UserInput!) {
    createUser(data: $data) {
      handle
    }
  }
`

type DataState = {
  handle: string
  email: string
  password: string
}

type HTMLField = {
  validation: { required: boolean; minLength: number; pattern: RegExp }
  message: string
}

// eslint-disable-next-line no-control-regex
const emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const SignUpFields = {
  handle: {
    type: 'text',
    validation: {
      required: true,
      minLength: 8,
    },
    message: (
      <Translation>{(t) => t('forms:user.signup.Handle.error')}</Translation>
    ),
  },
  email: {
    type: 'email',
    validation: {
      required: true,
      pattern: emailPattern,
    },
  },
  password: {
    type: 'password',
    validation: {
      required: true,
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
    },
  },
}

const SignUp: FunctionComponent = () => {
  const { t } = useTranslation()
  const { client } = useAuth()
  const [error, setError] = useState()
  const [createUser] = useMutation(MUTATION_CREATE_USER, {
    onCompleted: () => {
      location.reload()
    },
  })

  const onSubmit = ({ email, handle, password }: DataState) => {
    if (8 < handle.length && email.match(emailPattern)) {
      return client
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(({ user: { email } }) => {
          createUser({
            variables: {
              data: {
                handle,
                email,
              },
            },
          })
        })
        .catch(({ message }) => setError(message))
    } else {
      setError(t('forms:user.signup.error.general'))
    }
  }

  return (
    <Form onSubmit={onSubmit} className={'flex flex-col'}>
      <Text
        size={Sizes.big}
        color={Colors.primary}
        className={'mb-2 self-start'}
        pointer={'forms:user.signup.intro.old'}
      />
      {Object.entries(SignUpFields).map(([name, inputData]) => (
        <InputField
          key={name}
          {...inputData}
          name={name}
          label={t(`forms:user.signup.${name}.label`)}
          placeholder={t(`forms:user.signup.${name}.placeholder`)}
        />
      ))}
      <FormError />
      {error && (
        <Text color={Colors.alert} size={Sizes.standard}>
          {error}
        </Text>
      )}
      <Submit
        className={'w-full color-primary my-2 bg-white rounded border py-2'}
      >
        {t('forms:user.signup.signup')}
      </Submit>
    </Form>
  )
}

export default SignUp
