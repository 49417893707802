import React, { useMemo, ReactElement } from 'react'
import { navigate, routes } from '@redwoodjs/router'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@redwoodjs/auth'
import { ButtonComponent, Sizes } from 'src/ui/Enums'

import { Button } from 'src/ui/'

const UserMenu = (): ReactElement => {
  const { t } = useTranslation()

  const { isAuthenticated } = useAuth()

  const userMenuTabs = useMemo(
    () =>
      (isAuthenticated && [
        { route: routes.dashboard(), label: 'site:mainmenu.dashboardPage' },
        { route: routes.books(), label: 'site:mainmenu.ownershipsPage' },
        { route: routes.labels(), label: 'site:mainmenu.labelsPage' },
        { route: routes.profil(), label: 'site:mainmenu.profilPage' },
        { route: routes.contacts(), label: 'site:mainmenu.contactsPage' },
      ]) ||
      [],
    [isAuthenticated]
  )

  return (
    <>
      {userMenuTabs.map((tab) => {
        const { route, label } = tab
        const onClick = () => navigate(route)
        return (
          <Button
            component={ButtonComponent.link}
            href="#"
            key={`${route}-->${label}`}
            active={route === location.pathname}
            onClick={onClick}
            text={t(label)}
            size={Sizes.small}
          />
        )
      })}
    </>
  )
}
export default UserMenu
