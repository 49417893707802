// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { createElement, useMemo, useState } from 'react'
import { ButtonComponent, Colors, Sizes } from 'src/ui/Enums'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { TextProps } from 'src/ui'

type ButtonProps = TextProps & {
  text?: string
  onClick: () => void
  active?: boolean
  className?: string
  onClickHint?: boolean
}

const Button = ({
  onClick,
  onClickHint,
  text,
  pointer,
  active = false,
  className = '',
  color = Colors.primary,
  component = ButtonComponent.button,
  size = Sizes.standard,
  ...props
}: ButtonProps): JSX.Element => {
  const { t } = useTranslation()
  const _text = useMemo(() => (pointer ? t(pointer) : text), [pointer, text, t])
  const [_className, setClassName] = useState(
    classNames({
      [className]: true,
      [size]: size,
      [color]: color,
      'border shadow':
        component === ButtonComponent.button &&
        !className?.includes('border-0'),
      'px-4 py-2 bg-white':
        !className?.includes('p-') && component === ButtonComponent.button,
      'px-2': component !== ButtonComponent.button,
      'underline color-success': component === ButtonComponent.link && active,
      'color-secondary': component === ButtonComponent.link,
    })
  )

  const _onClick = useMemo(() => {
    if (onClickHint && component === ButtonComponent.button) {
      return () => {
        setClassName(`${_className} bg-blue-400 color-secondary`)
        onClick()
      }
    }
    return onClick
  }, [onClick, onClickHint, _className, component])

  /**
   * 'Local variable component is redundant' --> it was declared then returned, so useless, we skipped per Intelli's reco.
   */
  return useMemo(() => {
    return createElement(component, {
      ...props,
      className: `${_className}`,
      onClick: _onClick,
      children: [_text, props.children],
      type: 'button',
    })
  }, [component, _className, _onClick, _text, props])
}

export default Button

export { Button }
