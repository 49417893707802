import React, { createElement, FunctionComponent } from 'react'
import { Sizes } from 'src/ui/Enums'
import classNames from 'classnames'

import { Text } from 'src/ui'

import { GridProps } from './index'

export const Grid: FunctionComponent<GridProps> = ({
  className = '',
  collection,
  title,
  component,
  componentProps,
  filterUiPlaceholder,
}) => (
  <>
    {collection && Boolean(collection.length) && (
      <>
        {title && <Text size={Sizes.big} pointer={title} />}
        {filterUiPlaceholder}
        <div
          className={classNames({
            'flex flex-wrap': true,
            [className]: className,
            'justify-start': !className?.includes('justify-'),
          })}
        >
          {collection.map((item) =>
            createElement(component, {
              key: 'item-' + item.id,
              ...componentProps,
              ...item,
            })
          )}
        </div>
      </>
    )}
  </>
)

export default Grid
