/** Props enums. */
export enum Colors {
  primary = 'color-primary',
  secondary = 'color-secondary',
  discreet = 'color-discreet',
  success = 'color-success',
  alert = 'color-alert',
}

export enum Rates {
  sold,
  readIntent,
  buyIntent,
  keeper,
  required,
}

export enum Sizes {
  tiny = 'text-xs',
  small = 'text-sm',
  standard = 'text-base',
  big = 'text-2xl',
  massive = 'text-3xl',
}

/** Component enums. */
export enum FormVariants {
  wide,
  narrow,
}

export enum ButtonComponent {
  link = 'a',
  button = 'button',
}

export enum TypographyComponent {
  paragraph = 'p',
  text = 'span',
}
