import { createElement, useMemo, useState } from 'react'

import { Editor, Item } from 'src/ui'

import {
  Grid,
  useSelection,
  Selection,
  UseGridProps,
  UseGridReturn,
} from './index'

function collectionWithSelection(collection, onDelete, selectCallback) {
  return collection.map((c) => ({
    ...c,
    onClick: !onDelete ? selectCallback : undefined,
    onDelete,
  }))
}

function collectionWithEditor(collection, setEditorId, onDelete) {
  return collection.map((c) => ({
    ...c,
    onClick: () => setEditorId(c.id),
    onDelete,
  }))
}

function collectionBare(collection, onClick, onDelete) {
  return collection.map((c) => ({
    ...c,
    onClick,
    onDelete,
  }))
}

export const useGrid: (a: UseGridProps) => UseGridReturn = ({
  collection,
  component = Item,
  componentProps = { variant: 'preview' },
  editorCell,
  gridProps = { className: '', title: '' },
  withSelection = false,
  selectionProps = { variant: 'single' },
  selectionComponentProps = { component: Item, className: undefined },
  onClick,
  onDelete,
}) => {
  const {
    selection,
    deselectCallback,
    selectCallback,
    userHasSelected,
  } = useSelection(selectionProps)
  const [editorId, setEditorId] = useState<undefined | string>(undefined)

  const _withEditor = Boolean(editorCell)
  const _withSelection = !_withEditor && withSelection

  const editorComponent = useMemo(() => {
    if (_withEditor) {
      return editorCell && editorId
        ? createElement(Editor, {
            cell: editorCell,
            id: editorId,
            close: () => setEditorId(undefined),
          })
        : null
    }
    return null
  }, [editorCell, editorId, _withEditor])

  const _collection = useMemo(() => {
    if (collection) {
      if (_withSelection) {
        return collectionWithSelection(collection, onDelete, selectCallback)
      }
      if (_withEditor) {
        return collectionWithEditor(collection, setEditorId, onDelete)
      }
      if (!_withEditor && !_withSelection) {
        return collectionBare(collection, onClick, onDelete)
      }
    }
    return collection
  }, [
    _withSelection,
    _withEditor,
    collection,
    onDelete,
    onClick,
    selectCallback,
  ])

  /** Selection Component. **/
  const selectionComponent = useMemo<
    UseGridReturn['selectionComponent']
  >(() => {
    if (onDelete) {
      return null
    }
    const collection = Object.values(selection).map((i) => ({ ...i, data: i }))
    return createElement(Selection, {
      ...selectionProps,
      ...selectionComponentProps,
      collection,
      component,
      onDeselect: deselectCallback,
    })
  }, [
    onDelete,
    selection,
    selectionProps,
    selectionComponentProps,
    component,
    deselectCallback,
  ])

  /** Grid Component. **/
  const gridComponent = useMemo<UseGridReturn['gridComponent']>(
    () =>
      createElement(Grid, {
        ...gridProps,
        collection: _collection,
        component,
        componentProps,
      }),
    [_collection, component, componentProps, gridProps]
  )

  return {
    editorComponent,
    gridComponent,
    selectionComponent,
    selection,
    userHasSelected,
  }
}

export default useGrid
