import React from 'react'
import { Colors } from 'src/ui/Enums'

import { Text } from 'src/ui'

import Ownership from '../../../Ownership/Ownership'

export default function ReviewLoan({ ownerships, user }) {
  return (
    <>
      {user && Boolean(ownerships.length) && (
        <>
          <Text
            color={Colors.primary}
            pointer={`You are about to lend to ${user.name} the following books`}
          />
          {ownerships.map((o) => (
            <Ownership
              key={`Lending ${o.name}`}
              {...o}
              title={o.name}
              variant={'bare'}
            />
          ))}
        </>
      )}
    </>
  )
}
