import React, { createElement } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { FieldError } from '@redwoodjs/web'

export const Label = ({
  children = undefined,
  className,
  component,
  componentProps,
  name,
  label,
  ...inputProps
}) => {
  const { t } = useTranslation()
  const _className = classNames({
    [className]: className,
  })

  return (
    <>
      <label className={`flex flex-col m-2 ${_className}`}>
        {label && (
          <span className={'ml-4 text-grey-700 text-xs'}>{t(label)}</span>
        )}
        {component &&
          createElement(component, { ...inputProps, ...componentProps })}
        {children}
      </label>
      <FieldError name={name} className="color-alert" />
    </>
  )
}

export default Label
