import { createElement, useCallback, useState } from 'react'

import { Notification, Variants } from '../Notification'

export default function useNotification() {
  const [_notification, setNotification] = useState<null | {
    message: string
    variant: Variants
  }>()

  const cleanUp = useCallback(() => {
    setNotification(null)
  }, [setNotification])

  return {
    setNotification,
    notifier:
      _notification &&
      createElement(Notification, {
        ..._notification,
        cleanUp,
      }),
  }
}
