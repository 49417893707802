import React, { PropsWithChildren } from 'react'

import { ItemData, ItemEvents } from './index'

export type ItemChipProps<T> = PropsWithChildren<T> &
  Pick<ItemData, 'title' | 'data'> &
  ItemEvents<T>

export const ItemChip = ({ title, onDelete, onClick }: ItemChipProps<{}>) => (
  <div
    className="flex items-center m-2 py-2 px-4 border border-red-600 focus:border-blue-600 rounded-full color-secondary text-xs bg-white cursor-pointer"
    onClick={onClick}
  >
    {title}
    <a className={'pl-2'} onClick={onDelete}>
      <img
        className={'h-4'}
        src={
          'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSLQodC70L7QuV8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnIGlkPSJDYW5jZWwiPjxwYXRoIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE2LDBDNy4xNjMsMCwwLDcuMTYzLDAsMTZjMCw4LjgzNiw3LjE2MywxNiwxNiwxNiAgIGM4LjgzNiwwLDE2LTcuMTYzLDE2LTE2QzMyLDcuMTYzLDI0LjgzNiwwLDE2LDB6IE0xNiwzMEM4LjI2OCwzMCwyLDIzLjczMiwyLDE2QzIsOC4yNjgsOC4yNjgsMiwxNiwyczE0LDYuMjY4LDE0LDE0ICAgQzMwLDIzLjczMiwyMy43MzIsMzAsMTYsMzB6IiBmaWxsPSIjMTIxMzEzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMi43MjksMjEuMjcxbC01LjI2OC01LjI2OWw1LjIzOC01LjE5NSAgIGMwLjM5NS0wLjM5MSwwLjM5NS0xLjAyNCwwLTEuNDE0Yy0wLjM5NC0wLjM5LTEuMDM0LTAuMzktMS40MjgsMGwtNS4yMzEsNS4xODhsLTUuMzA5LTUuMzFjLTAuMzk0LTAuMzk2LTEuMDM0LTAuMzk2LTEuNDI4LDAgICBjLTAuMzk0LDAuMzk1LTAuMzk0LDEuMDM3LDAsMS40MzJsNS4zMDEsNS4zMDJsLTUuMzMxLDUuMjg3Yy0wLjM5NCwwLjM5MS0wLjM5NCwxLjAyNCwwLDEuNDE0YzAuMzk0LDAuMzkxLDEuMDM0LDAuMzkxLDEuNDI5LDAgICBsNS4zMjQtNS4yOGw1LjI3Niw1LjI3NmMwLjM5NCwwLjM5NiwxLjAzNCwwLjM5NiwxLjQyOCwwQzIzLjEyMywyMi4zMDgsMjMuMTIzLDIxLjY2NywyMi43MjksMjEuMjcxeiIgZmlsbD0iIzEyMTMxMyIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9nPjxnLz48Zy8+PGcvPjxnLz48Zy8+PGcvPjwvc3ZnPg=='
        }
      />
    </a>
  </div>
)

export default ItemChip
