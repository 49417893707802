import React, { createElement, FunctionComponent } from 'react'
import { Sizes } from 'src/ui/Enums'

import { Item, Text } from 'src/ui'

import { SelectionProps } from './index'

export const Selection: FunctionComponent<SelectionProps> = ({
  collection,
  component = Item,
  variant = 'single',
  onDeselect,
  className,
  title,
}) => (
  <div>
    {title && <Text size={Sizes.big} pointer={title} className={'w-full'} />}
    <div className={`flex flex-wrap ${className}`}>
      {collection &&
        collection.map((i) =>
          createElement(component, {
            key: i.title,
            ...i,
            variant: 'single' === variant ? 'preview' : 'chip',
            onDelete: onDeselect,
          })
        )}
    </div>
  </div>
)
export default Selection
