import React, { useCallback, useEffect, useMemo } from 'react'
import { useQuery } from '@redwoodjs/web'
import gql from 'graphql-tag'

import { SelectField } from 'src/ui'

const QUERY = gql`
  query CONTACTS {
    contacts {
      id
      handle
      email
    }
  }
`

function compose(contacts) {
  return [
    ...contacts.map(({ id, handle, email }) => ({
      id,
      name: `${handle} ${email}`,
    })),
  ]
}

export default function SelectContact({ onChange }) {
  /**
   * Vars
   */
  const { data } = useQuery(QUERY)

  const options = useMemo(() => data?.contacts && compose(data.contacts), [
    data,
  ])

  /**
   * Effects
   */
  useEffect(() => {
    if (options && options.length === 1) {
      onChange(options)
    }
  }, [onChange, options])

  /**
   * Callbacks
   */
  const _onChange = useCallback(
    ({ currentTarget: { value } }) => onChange(value),
    [onChange]
  )

  return (
    <>
      {options && options.length > 1 && (
        <SelectField
          label={'Contact'}
          name={'contact'}
          onChange={_onChange}
          placeholder={'Select a friend'}
        >
          {options.map(({ id, name }) => (
            <option key={`Select ${name}`} value={id}>
              {name}
            </option>
          ))}
        </SelectField>
      )}
    </>
  )
}
