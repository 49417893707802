import React, { PropsWithChildren, useMemo } from 'react'
import { Colors, Sizes, TypographyComponent } from 'src/ui/Enums'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Typography } from 'src/ui'

export type TextProps = Typography &
  PropsWithChildren<Record<string, unknown>> & {
    className?: string
    pointer?: string
  }

export const Text = ({
  className = '',
  size = Sizes.standard,
  component = TypographyComponent.paragraph,
  color = Colors.discreet,
  pointer,
  children,
}: TextProps): JSX.Element => {
  const { t } = useTranslation()
  const _className = useMemo(
    () =>
      classNames({
        [className]: className,
        [size]: size,
        [component]: component,
        [color]: color,
        'text-title': [Sizes.big, Sizes.massive].includes(size),
      }),
    [className, color, component, size]
  )

  const _content = useMemo(() => (!pointer ? children : t(pointer)), [
    children,
    pointer,
    t,
  ])

  return <p className={_className}>{_content}</p>
}

export default Text
