import React from 'react'
import { useMutation } from '@redwoodjs/web'
import { navigate, routes } from '@redwoodjs/router'
import gql from 'graphql-tag'
import User from 'src/components/User/User'
import useNotification from 'src/ui/useNotification/useNotification'

import { CellEmpty, useGrid } from 'src/ui'

export const QUERY = gql`
  query Contacts {
    contacts {
      id
      firstname
      lastname
      email
    }
  }
`

const MUTATION_DELETE_CONTACT = gql`
  mutation DeleteContact($id: String!) {
    deleteContact(id: $id) {
      status
      message
    }
  }
`

export const Empty = () => <CellEmpty pointer={'pages:contacts.empty'} />

export const Success = ({ contacts }) => {
  const { notifier, setNotification } = useNotification()

  /** Mutations & Queries. **/

  const [deleteContact] = useMutation(MUTATION_DELETE_CONTACT, {
    refetchQueries: [{ query: QUERY }],
    onCompleted: ({ status, message }) =>
      setNotification({ variant: status, message }),
  })

  const { gridComponent } = useGrid({
    collection: contacts,
    component: User,
    componentProps: { className: 'w-full md:w-1/6' },
    onClick({ id }) {
      navigate(routes.user({ id }))
    },
    onDelete: function ({ id }) {
      if (confirm('Parting ways, are we?')) {
        deleteContact({ variables: { id } })
      }
    },
    gridProps: {
      title: 'features:contact.Contacts',
      className: 'justify-start',
    },
  })

  return (
    <div>
      {notifier}
      {gridComponent}
    </div>
  )
}
