import React, { createElement, useCallback, useMemo } from 'react'

import { OrNull } from 'src/ui'

import ItemChip from './ItemChip'
import Preview from './Preview'

import { ItemProps } from './'
import './item.scss'

export const Item = (props: ItemProps<{}>) => {
  /** WARNING: there's an issue with how onClick and onDelete are implemented.
   * Item gives them both its data object, but all its properties are not necessary built with data.
   * This is better illustrated with a useGrid + useSelection story and possibly a complex Item type.
   * The solution to consider here is forcing that ItemProps exposes item properties & item data and
   * making sure derivatives of Item implemented such type.
   * Conceptually it feels shitty, but it can work.
   */
  /** Callbacks. */
  const _onClick = useCallback(
    function () {
      const { onClick, data } = props
      return onClick ? (e) => onClick(data, e) : null
    },
    [props]
  )

  const _onDelete = useCallback(
    function () {
      const { onDelete, data } = props
      return onDelete ? () => onDelete(data) : null
    },
    [props]
  )

  const _props = useMemo<
    ItemProps<{}> & {
      onClick: OrNull<Function>
      onDelete: OrNull<Function>
    }
  >(
    () => ({
      ...props,
      onClick: _onClick(),
      onDelete: _onDelete(),
    }),
    [props, _onClick, _onDelete]
  )
  const component = useMemo(() => {
    const shouldBeChip =
      _props.variant === 'chip' ||
      Boolean(
        !_props.variant &&
          _props.title &&
          !_props.subtitle &&
          !_props.details &&
          !_props.avatarProps
      )
    // @ts-ignore
    return createElement(shouldBeChip ? ItemChip : Preview, _props)
  }, [_props])

  return component
}

export default Item
