import React, { FunctionComponent } from 'react'
import { Translation } from 'react-i18next'

import { TranslatedContent, Text } from 'src/ui'

export const CellLoading: FunctionComponent<TranslatedContent> = ({
  pointer,
}) => <Translation>{(t) => <Text>{t(pointer)}</Text>}</Translation>

export default CellLoading
