import { useCallback, useEffect, useMemo, useReducer } from 'react'

import {
  SelectionCallback,
  InnerSelectionState,
  UseSelectionProps,
  UseSelectionReturn,
} from './index'

const selectSingle: SelectionCallback = (input) => ({ [input.id]: input })

const selectMultiple: SelectionCallback = (input, state) => ({
  ...state,
  [input.id]: input,
})

const deselectSingle: SelectionCallback = () => ({})

const deselectMultiple: SelectionCallback = (input, state) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [input.id]: deletedInput, ..._state } = state
  return _state
}

const selectionState: InnerSelectionState = {
  selection: {},
  userHasSelected: false,
}

const reducer = (state, action): InnerSelectionState => {
  const { type, selection } = action
  if ('event' === type) {
    return { ...state, selection, userHasSelected: true }
  }
  return { ...state, selection }
}

export const useSelection: (a: UseSelectionProps) => UseSelectionReturn = ({
  variant = 'single',
  preSelection = {},
}) => {
  const [{ selection, userHasSelected }, setSelection] = useReducer(
    reducer,
    selectionState
  )

  useEffect(() => {
    if (preSelection && Object.keys(preSelection).length > 0) {
      setSelection({ selection: preSelection })
    }
  }, [preSelection])

  const _selectCallback = useMemo(
    () => ('multiple' === variant ? selectMultiple : selectSingle),
    [variant]
  )

  const _deselectCallback = useMemo(
    () => ('multiple' === variant ? deselectMultiple : deselectSingle),
    [variant]
  )

  const selectCallback = useCallback(
    (data) => {
      const s = _selectCallback(data, selection)
      setSelection({ selection: s, type: 'event' })
    },
    [_selectCallback, selection]
  )

  const deselectCallback = useCallback(
    (data) => {
      const s = _deselectCallback(data, selection)
      setSelection({ selection: s, type: 'event' })
    },
    [_deselectCallback, selection]
  )

  return { selectCallback, deselectCallback, selection, userHasSelected }
}

export default useSelection
