import React, { FunctionComponent } from 'react'

import { Item, ItemProps } from 'src/ui'

type LabelProps = ItemProps<object> & {
  id: string
  name: string
}

export const Label: FunctionComponent<LabelProps> = ({
  id,
  name,
  onDelete,
  ...props
}) => (
  <Item
    {...props}
    title={name}
    data={{ id, title: name }}
    subtitle={`Books: ${props.ownershipsCount}`}
    variant={'preview'}
    onDelete={onDelete}
  />
)

export default Label
