import React, { FunctionComponent, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { ItemComponentProps } from 'src/ui/Item'
import { Sizes } from 'src/ui/Enums'

import { Button, Item, Rate, Text } from 'src/ui'

import { LifeCycle } from '../../ui/Cycle/Cycle'

export type OwnershipData = {
  book
  rate
  note
  labels
  createdAt
  updatedAt
  id
}

export type OwnershipProps<T> = PropsWithChildren<T> &
  ItemComponentProps<T> &
  OwnershipData

const Ownership: FunctionComponent<OwnershipProps<object>> = (props) => {
  const _compose = ({
    id,
    className,
    variant,
    rate,
    labels,
    book,
    stage,
    ...props
  }) => ({
    variant,
    ...props,
    className,
    title: book.title,
    details: [
      <span
        className={'flex p-2 w-0 border-0'}
        key={`${book.title}--stage`}
        style={{
          color: 'white',
          backgroundColor: LifeCycle[stage].color,
        }}
      />,
      Boolean(book?.authors?.length) && (
        <span key={`${book.title}--authors`}>
          <Text size={Sizes.tiny}>
            {book.authors.map(({ name }) => name).join(', ')}
          </Text>
        </span>
      ),
      rate && (
        <div key={`${book.title}--rates`}>
          <Rate selected={rate} />
        </div>
      ),
    ],
    avatarProps: { src: book.covers, variant: 'rounded', size: Sizes.big },
    data: { id, rate, book, labels },
  })

  return <Item {..._compose(props)} />
}

export default Ownership
