import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@redwoodjs/web'
import useNotification from 'src/ui/useNotification/useNotification'
import { QUERY as OwnershipsQuery } from 'src/components/Ownership/OwnershipsCell/OwnershipsCell'

import { Cycle } from 'src/ui'

import Book from '../../../Book/Book'

const CONFIRM_OWNERSHIP = gql`
  mutation QueryClaimOwnership($bookId: String!, $stage: String) {
    claimBookOwnership(bookId: $bookId, stage: $stage) {
      message
      status
    }
  }
`

export default function CollectSelectedReference({
  record,
  selectedReference,
}) {
  const { setNotification, notifier } = useNotification()

  /**
   * Rating call!
   */
  const [claimOwnership] = useMutation(CONFIRM_OWNERSHIP, {
    refetchQueries: [{ query: OwnershipsQuery }],
    onCompleted: ({ claimBookOwnership: { status, message } }) => {
      setNotification({
        message: status === 'SUCCESS' ? 'Book added to your library' : message,
        variant: status,
      })
    },
  })

  const ratingIn = (stage) => {
    record({ stage })
    claimOwnership({
      variables: { bookId: selectedReference.id, stage },
    })
  }

  return (
    <div className={'mt-2 space-y-4'}>
      {/** Display the notification. **/ notifier}
      {selectedReference && (
        <>
          <Book {...selectedReference} />
          <Cycle onChange={ratingIn} />
        </>
      )}
    </div>
  )
}

export { CollectSelectedReference }
