import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContactMail } from '@material-ui/icons'
import AddContact from 'src/components/Contact/AddContact'

import { Form as FormUi } from 'src/ui'

const Action = () => ({
  title: 'features:contact.Add',
  icon: <ContactMail />,
  tooltipTitle: 'features:contact.Add',
})
export const Pointer = 'rights.canAddContacts'

const Form = () => {
  const { t } = useTranslation()
  return (
    <FormUi variant={'narrow'} title={t('forms:contact.add.title')}>
      <AddContact />
    </FormUi>
  )
}

export { Action, Form }
