import React from 'react'
import { SelectField as LibSelectField } from '@redwoodjs/web'

import { Field, Label } from 'src/ui'

export const SelectField = ({
  children,
  className,
  label,
  name,
  ...props
}: Field) => (
  <Label
    className={className}
    label={label}
    {...props}
    name={name}
    component={LibSelectField}
    componentProps={{ className: 'form-select', name, children }}
  />
)

export default SelectField
