import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import classNames from 'classnames'
import 'react-quill/dist/quill.snow.css' // ES6

type RichEditor = {
  className?: string
  onSave: (value) => void
  value: string
}

export function RichEditor({ className = '', onSave, value }: RichEditor) {
  const [_value, setValue] = useState<string>(value)
  const _className = classNames({
    'flex flex-col bg-white': true,
    [className]: className,
  })
  return (
    <ReactQuill
      className={_className}
      theme={'snow'}
      onChange={setValue}
      value={_value}
      onBlur={(_previousRange, _source, editor) => onSave(editor.getHTML())}
    />
  )
}

export default RichEditor
