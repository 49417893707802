import React, { useCallback, useMemo } from 'react'
import { Translation } from 'react-i18next'
import { useMutation, useQuery } from '@redwoodjs/web'
import { navigate, routes } from '@redwoodjs/router'
import gql from 'graphql-tag'
import Ownership from 'src/components/Ownership/Ownership'
import { Sizes } from 'src/ui/Enums'
import LoansCell from 'src/components/Loan/LoansCell'

import {
  CycleSelect,
  Filters,
  InputField,
  predicateOnArray,
  SelectingField,
  Text,
  Tip,
  useFiltering,
  useGrid,
} from 'src/ui'

import { LifeCycle } from '../../../ui/Cycle/Cycle'

const QUERY_LABELS = gql`
  query getLabels {
    labels {
      name
      id
    }
  }
`

const filterLabels = (options) => ({
  component: SelectingField,
  componentProps: {
    label: 'Labels',
    options,
  },
  name: 'labels',
  fn({ labels }, filterValue) {
    return filterValue.length
      ? predicateOnArray(labels).filter((v) => filterValue.includes(v)).length
      : true
  },
})

const filters: Filters = [
  {
    component: InputField,
    componentProps: {
      label: 'forms:books.grid.title',
    },
    name: 'title',
    fn: ({ book: { title } }, filterValue) =>
      title.toLowerCase().includes(filterValue),
  },
  {
    component: InputField,
    componentProps: {
      label: 'forms:books.grid.author',
    },
    name: 'author',
    fn: ({ book: { authors } }, filterValue) =>
      authors
        .map(({ name }) => name.toLowerCase())
        .filter((name) => name.includes(filterValue)).length,
  },
  {
    component: CycleSelect,
    componentProps: { label: 'Stage' },
    name: 'stage',
    fn({ stage }, filterValue) {
      return 'all' === filterValue || stage === filterValue
    },
  },
]

export const QUERY = gql`
  query GET_OWNERSHIPS {
    ownerships {
      id
      rate
      stage
      book {
        title
        subtitle
        covers
        authors {
          name
        }
      }
      labels {
        id
        name
      }
    }
  }
`

const DELETE_POST_MUTATION = gql`
  mutation DeleteOwnershipMutation($id: String!) {
    deleteOwnership(id: $id) {
      id
    }
  }
`

export const beforeQuery = (props) => {
  return { variables: props, fetchPolicy: 'cache-and-network' }
}

export const Loading = () => (
  <div>
    <Text size={Sizes.standard}>
      <Translation>{(t) => t('site:loading')}</Translation>
    </Text>
  </div>
)

export const Empty = () => <Tip pointer={'features:book.Empty'} />

export const Failure = ({ error }) => <div>Error: {error.message}</div>

const stagesForSort = Array.from(Object.keys(LifeCycle)).reverse()

const sort = (a, b) =>
  stagesForSort.indexOf(a.stage) - stagesForSort.indexOf(b.stage)

export const Success = ({ ownerships }: { ownerships: Array<any> }) => {
  const { data } = useQuery(QUERY_LABELS)
  const _filters = useMemo(
    () => (data?.labels && [...filters, filterLabels(data.labels)]) || filters,
    [data]
  )

  const { filterUi, results } = useFiltering({
    collection: ownerships.sort(sort),
    filters: _filters,
    filterThreshold: 10,
    uiProps: { displayNb: 4 },
  })

  const { gridComponent } = useGrid({
    collection: results,
    component: Ownership,
    componentProps: {
      variant: 'full',
      className: 'w-64',
    },
    gridProps: { title: 'features:book.Books' },
    onClick({ id }) {
      navigate(routes.ownership({ id }))
    },
  })

  /** Mutations & Queries. **/
  const [deleteOwnership] = useMutation(DELETE_POST_MUTATION, {
    refetchQueries: [{ query: QUERY }],
    onCompleted: () => {
      // TODO: display notification.
      // location.reload()
    },
  })

  /** Callbacks. **/
  const onDeleteClick = (id) => {
    if (confirm('Are you sure you want to delete ownership ' + id + '?')) {
      deleteOwnership({ variables: { id } })
    }
  }

  return (
    <>
      {filterUi}
      {gridComponent}
      <Tip pointer={'tips:ownerships.feeding'} variant={'narrow'} />
    </>
  )
}
