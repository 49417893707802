import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

function dig(pointer: string, native) {
  const [next, ...remain]: string[] = pointer.split('.')
  return remain.length ? dig(remain.join('.'), native[next]) : native[next]
}

export default function useConfiguration(
  pointers: Array<string> | null
): Record<string, boolean | Record<string, boolean | object>> | any {
  const [digged, setDigged] = useState<{} | undefined>()
  const configuration = useSelector(({ configuration }) => configuration)

  useEffect(() => {
    if (configuration && !digged && pointers) {
      setDigged(
        Object.fromEntries(
          pointers.map((pointer) => [
            pointer.split('.').pop(),
            dig(pointer, configuration),
          ])
        )
      )
    }
  }, [configuration, digged, pointers])

  return !pointers
    ? null
    : {
        ...digged,
      }
}
