import React from 'react'
import { Translation } from 'react-i18next'

import { SelectField } from 'src/ui'

type CycleSelectProps = {
  className?: string
  onChange: (value: string) => void
}

export const CycleSelect = ({
  className,
  onChange,
  ...props
}: CycleSelectProps) => (
  <Translation>
    {(t) => (
      <SelectField
        name={'stage'}
        className={className}
        label={'Stage'}
        onChange={onChange}
        {...props}
      >
        {['all', 'not_owned', 'prospect', 'idle', 'important', 'required'].map(
          (value) => (
            <option key={value} value={value}>
              {t(`features:lifecycle.${value}.label`)}
            </option>
          )
        )}
      </SelectField>
    )}
  </Translation>
)
export default CycleSelect
