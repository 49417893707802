import React, { FunctionComponent } from 'react'
import { IconButton } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import classNames from 'classnames'
import { Colors, Sizes } from 'src/ui/Enums'
import Skeleton from 'react-loading-skeleton'

import { Avatar, ItemProps, Text } from 'src/ui'

import ClickOrNot from './ClickOrNot'

export const Preview: FunctionComponent<ItemProps<{}>> = ({
  variant = 'preview',
  title,
  bottomChildren,
  className = '',
  avatarProps,
  details,
  subtitle,
  onClick,
  onDelete,
}) => (
  <ClickOrNot
    onClick={onClick}
    className={`item flex flex-col flex-wrap m-2 justify-between md:flex-row ${className}`}
  >
    {
      /** On Preview and above, we display some picture. * */ variant !==
        'bare' &&
        avatarProps &&
        ((
          <div className="hidden md:flex p-2 justify-center md:w-1/4 md:p-0">
            <Avatar {...avatarProps} />
          </div>
        ) || <Skeleton />)
    }
    <div
      className={classNames({
        'flex flex-col flex-1 p-2 justify-start truncate': true,
        'ml-2': variant === 'bare',
      })}
    >
      <Text
        size={Sizes.small}
        color={Colors.secondary}
        className={'h-10 truncate'}
      >
        {title}
      </Text>
      {subtitle && (
        <Text size={Sizes.tiny} className={'truncate'}>
          {subtitle}
        </Text>
      )}
      {
        /** On Full, we display some details! * */ variant === 'full' &&
          details && <div className="flex flex-col md:max-w-xs">{details}</div>
      }
    </div>
    {onDelete && (
      <div className="hidden md:flex items-center justify-center md:w-1/4">
        <div className="flex w-4 h-4">
          <IconButton onClick={() => onDelete({ id: 9 })}>
            <HighlightOffIcon />
          </IconButton>
        </div>
      </div>
    )}
    {bottomChildren && (
      <div className={'flex p-2 w-full justify-center'}>{bottomChildren}</div>
    )}
  </ClickOrNot>
)
export default Preview
